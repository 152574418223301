import { calcWinsize, getMousePos, getRandomNumber, lerp, map } from "./utils";
import { forwardRef, useCallback, useEffect, useMemo, useRef } from "react";
import { node, number } from "prop-types";

import { gsap } from "gsap";

const propTypes = {
  index: number,
  image: node,
};

const TimelinePromoImage = forwardRef((props, ref) => {
  const { index, image } = props;

  // Calculate the viewport size
  let winsize = calcWinsize();
  if (typeof window !== "undefined") {
    window.addEventListener("resize", () => (winsize = calcWinsize()));
  }

  // Track the mouse position
  let mousepos = useMemo(
    () => ({ x: winsize.width / 2, y: winsize.height / 2 }),
    [winsize]
  );
  if (typeof window !== "undefined") {
    window.addEventListener("mousemove", (ev) => (mousepos = getMousePos(ev)));
  }

  // amounts to move in each axis
  let translationVals = { tx: 0, ty: 0 };

  // get random start and end movement boundaries
  const xstart = getRandomNumber(15, 60);
  const ystart = getRandomNumber(15, 60);

  const imageRef = useRef();

  // infinite loop
  const render = useCallback(() => {
    // Calculate the amount to move.
    // Using linear interpolation to smooth things out.
    // Translation values will be in the range of [-start, start] for a cursor movement from 0 to the window's width/height
    translationVals.tx = lerp(
      translationVals.tx,
      map(mousepos.x, 0, winsize.width, -xstart, xstart),
      0.07
    );
    translationVals.ty = lerp(
      translationVals.ty,
      map(mousepos.y, 0, winsize.height, -ystart, ystart),
      0.07
    );

    if (imageRef.current) {
      gsap.set(imageRef.current, {
        x: translationVals.tx,
        y: translationVals.ty,
      });
    }

    requestAnimationFrame(render);
  }, [translationVals, mousepos, winsize]);

  useEffect(() => {
    requestAnimationFrame(render);
    return () => cancelAnimationFrame(imageRef.current);
  }, [render]);

  return (
    <div
      ref={(node) => {
        imageRef.current = node;
        ref(node);
      }}
      key={index}
      className={`timeline-promo__image timeline-promo__image--${index + 1}`}
    >
      {image}
    </div>
  );
});

TimelinePromoImage.propTypes = propTypes;

export default TimelinePromoImage;
