import * as R from "ramda";

import extractImage from "./extractImage.jsx";
import extractLink from "./extractLink.js";
import extractRichText from "./extractRichText.jsx";

const extractTimelinePromo = R.compose(
  R.applySpec({
    headline: R.compose(extractRichText, R.prop("headline")),
    description: R.compose(extractRichText, R.prop("description")),
    ctaLink: R.compose(extractLink, R.prop("ctaLink")),
    images: R.compose(R.map(extractImage), R.prop("images")),
    backgroundImage: R.compose(extractImage, R.prop("backgroundImage")),
  }),
  R.defaultTo({}),
);

export default extractTimelinePromo;
