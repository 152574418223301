import "./quote.scss";

import { node } from "prop-types";
import useAOS from "../../common/useAOS.js";

const propTypes = {
  quoteImage: node,
  quoteText: node.isRequired,
  quoteSource: node,
};

const Component = (props) => {
  const { quoteImage, quoteText, quoteSource } = props;

  useAOS();

  return (
    <figure data-aos="fade-down" className="quote">
      {quoteImage && <div className="quote__img-container">{quoteImage}</div>}
      <div className="quote__quote-container">
        <div className="quote__quote-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.9 66.28">
            <path
              d="M41.35 6.72a20.75 20.75 0 016.76 16 34.78 34.78 0 01-1.26 9.11 84.6 84.6 0 01-5.81 13.57L30.98 65.78H5.73l6.85-23.8a19.37 19.37 0 01-8.92-7.58A21.58 21.58 0 01.5 22.68a20.75 20.75 0 016.76-16q6.76-6.22 17-6.22t17.09 6.26zm52.29 0a20.71 20.71 0 016.76 16 34.37 34.37 0 01-1.26 9.11 84.34 84.34 0 01-5.77 13.61l-10.1 20.34H58.04l6.85-23.8a19.4 19.4 0 01-8.93-7.58 21.66 21.66 0 01-3.15-11.72 20.71 20.71 0 016.76-16q6.76-6.22 17-6.22t17.07 6.26z"
              fill="none"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
        <blockquote className="quote__text h2">
          <strong>{quoteText}</strong>
        </blockquote>
        {quoteSource && (
          <figcaption>
            <cite className="quote__source">{quoteSource}</cite>
          </figcaption>
        )}
      </div>
    </figure>
  );
};

Component.propTypes = propTypes;
export default Component;
