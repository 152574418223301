import "./movement-moment.scss";

import * as R from "ramda";

import { node, string } from "prop-types";

import cx from "classnames";

const propTypes = {
  boxTitle: string,
  image: node,
  headline: string,
  description: node,
};

const MovementMoment = (props) => {
  const { boxTitle, image, headline, description } = props;

  const hasImage = R.path(["props", "image"])(image);

  return (
    <div className="movement-moment">
      <div className="movement-moment__frame">
        <strong className="movement-moment__box-title">{boxTitle}</strong>
        <div
          className={cx("movement-moment__card", {
            "movement-moment__card--no-image": !hasImage,
          })}
        >
          <div className="movement-moment__img-wrapper">{image}</div>
          <div className="movement-moment__card-content">
            <h2 className="movement-moment__headline">
              <strong>{headline}</strong>
            </h2>
            <p className="movement-moment__description">{description}</p>
          </div>
        </div>
      </div>
      <div className="movement-moment__shadow"></div>
    </div>
  );
};

MovementMoment.propTypes = propTypes;

export default MovementMoment;
