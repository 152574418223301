import "./timeline-slide.scss";

import { node, string } from "prop-types";

const propTypes = {
  text: string.isRequired,
  year: string.isRequired,
  image: node,
};

const TimelineSlide = (props) => {
  const { text, year } = props;

  return (
    <div className="timeline-slide">
      <div className="timeline-slide__content">
        <div className="timeline-slide__year">{year}</div>
        <div className="timeline-slide__text">{text}</div>
      </div>
    </div>
  );
};

TimelineSlide.propTypes = propTypes;
export default TimelineSlide;
