import * as R from "ramda";

export default R.compose(
  R.applySpec({
    textLine1: R.prop("line1"),
    textLine2: R.prop("line2"),
  }),
  R.defaultTo({}),
);

// {
//   __typename: "ContentfulOutlineTextPromo",
//   contentful_id: "16uwZ8JpH6NmAAEo1obbf5",
//   line1: "It takes all of us",
//   line2: "It takes all of us",
// };
