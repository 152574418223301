import "./timeline.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  A11y,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper/core";
import { useEffect, useRef } from "react";

import Container100 from "../Container/Container100.jsx";
import { array } from "prop-types";
import { scrollToElement } from "../../common/scrollHelpers.js";
import scrollama from "scrollama";
import useAOS from "../../common/useAOS.js";
import { useState } from "react";
import useWindowSize from "../../common/useWindowSize.js";

const propTypes = { sections: array };

SwiperCore.use([Navigation, A11y, Mousewheel, Keyboard, Pagination]);

const Timeline = (props) => {
  const { sections } = props;
  const [activeSection, setActiveSection] = useState(null);
  const [progress, setProgress] = useState({});
  const [swipers, setSwipers] = useState([]);

  const refs = useRef([]);

  const [windowWidth] = useWindowSize();

  useAOS();

  useEffect(() => {
    const scroller = scrollama();

    scroller
      .setup({
        step: ".js-section",
        offset: 0.1,
        progress: true,
      })
      .onStepProgress((response) => {
        setProgress((prevState) => ({
          ...prevState,
          [response.index]: response.progress,
        }));
      })
      .onStepEnter(({ direction, index }) => {
        setActiveSection(index);
      });
  }, []);

  useEffect(() => {
    if (swipers.length > 0) {
      swipers.forEach((swiper, i) => {
        const scroller = scrollama();
        scroller
          .setup({
            step: `.js-step-${i}`,
            offset: 0.2,
          })
          .onStepEnter(({ direction, index }) => {
            swiper.slideTo(index, 400);
          });
      });
    }
  }, [swipers]);

  return (
    <section className="timeline js-timeline">
      <div className="timeline__header">
        <div className="timeline__header-title">
          {sections[activeSection]?.title}
        </div>
        <div className="timeline__progress-bar">
          {sections &&
            sections.map((section, idx) => {
              const width = `${progress[idx] * 100 || 0}%`;

              return (
                <div className="timeline__progress-bar-progress" key={idx}>
                  <div
                    className="timeline__progress-bar-path"
                    style={{ width }}
                  ></div>
                </div>
              );
            })}
        </div>
        <div className="timeline__btn-wrapper">
          <a href="#missing-something" className="timeline__header-btn">
            Are we missing something?
          </a>
          <button
            className="timeline__header-btn btn"
            onClick={() => {
              setActiveSection(activeSection - 1);
              scrollToElement(refs.current[activeSection - 1]);
            }}
            disabled={activeSection === 0}
            type="button"
            aria-label="Previous Section"
          >
            {chevronLeft} <span>Previous Section</span>
          </button>
          <button
            className="timeline__header-btn btn"
            onClick={() => {
              setActiveSection(activeSection + 1);
              scrollToElement(refs.current[activeSection + 1]);
            }}
            disabled={sections.length === activeSection + 1}
            type="button"
            aria-label="Next Section"
          >
            <span>Next Section</span> {chevronRight}
          </button>
        </div>
      </div>
      {sections?.map((section, i) => (
        <div className="js-section" key={`section-${i}`}>
          <div
            ref={(ref) => (refs.current[i] = ref)}
            className="timeline__section-header "
          >
            <div className="timeline__section-bg-image">
              {section.backgroundImage}
              <Container100>
                <div
                  data-aos="fade-down"
                  className="timeline__section-content-wrapper"
                >
                  <div className="timeline__section-year">{section?.years}</div>
                  <h2 className="timeline__section-headline">
                    {section?.headline}
                  </h2>
                  <div className="timeline__section-subtitle">
                    {section?.subtitle}
                  </div>
                </div>
              </Container100>
            </div>
          </div>
          {windowWidth >= 746 ? (
            <div className="timeline__section-slider">
              <div>
                add an extra trigger so the last one doesnt get hidden
                {section.slides.concat([0]).map((_, j) => (
                  <div
                    key={"scroll-trigger" + j}
                    className={`timeline__scroll-trigger js-step-${i}`}
                  />
                ))}
              </div>
              <div className="timeline__slider">
                <Swiper
                  a11y
                  keyboard
                  slidesPerView={1}
                  spaceBetween={0}
                  freeMode={false}
                  mousewheel={false}
                  pagination
                  onSwiper={(swiper) =>
                    setSwipers((prevState) => [...prevState, swiper])
                  }
                  className='timeline__slider__inner'
                >
                  {section?.slides.map((slide, i) => (
                    <SwiperSlide key={`slide-${i}`}>{slide}</SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          ) : (
            <div>{section?.slides.map((slide, i) => slide)}</div>
          )}
        </div>
      ))}
    </section>
  );
};

Timeline.propTypes = propTypes;
export default Timeline;

const chevronRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-chevron-right"
  >
    <polyline points="9 18 15 12 9 6" />
  </svg>
);

const chevronLeft = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-chevron-left"
  >
    <polyline points="15 18 9 12 15 6"></polyline>
  </svg>
);
