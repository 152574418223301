import * as R from "ramda";

import extractImage from "./extractImage.jsx";
import { renderRichText } from "gatsby-source-contentful/rich-text";

// NOTE we're using renderRichText instead of extractRichText because we only need
// to render rich text and not embedded assets/entries that extractRichText provides.
// If we import extractRichText to this file, it will create a circular dependency!
// This will mean that extractRichText will be undefined initially until the circular
// dependency is resolved, so the point-free verson of this functin will break!
export default R.compose(
  R.applySpec({
    quoteText: R.compose(renderRichText, R.prop("quoteText")),
    quoteSource: R.compose(renderRichText, R.prop("quoteSource")),
    quoteImage: R.compose(extractImage, R.prop("quoteImage")),
  }),
  R.defaultTo({}),
);

// {
//   __typename: "ContentfulQuote",
//   id: "f21171c9-3814-5af2-a84d-846953d32316",
//   quoteText: {
//     raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Friends are the bacon bits in the salad bowl of life.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
//   },
//   quoteSource: {
//     raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Homer Simpson","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
//   },
//   quoteImage: {
//     gatsbyImageData: {
//       images: {
//         sources: [
//           {
//             srcSet:
//               "//images.ctfassets.net/qyzml03adckj/3LPBETg8iQQBIouzz8LKkp/1984946dd3803afa73003210bf23a622/avacado.jpg?w=69&h=46&q=50&fm=webp 69w,\n//images.ctfassets.net/qyzml03adckj/3LPBETg8iQQBIouzz8LKkp/1984946dd3803afa73003210bf23a622/avacado.jpg?w=138&h=92&q=50&fm=webp 138w,\n//images.ctfassets.net/qyzml03adckj/3LPBETg8iQQBIouzz8LKkp/1984946dd3803afa73003210bf23a622/avacado.jpg?w=275&h=183&q=50&fm=webp 275w",
//             sizes: "(min-width: 275px) 275px, 100vw",
//             type: "image/webp",
//           },
//         ],
//         fallback: {
//           src: "//images.ctfassets.net/qyzml03adckj/3LPBETg8iQQBIouzz8LKkp/1984946dd3803afa73003210bf23a622/avacado.jpg?w=275&h=183&fl=progressive&q=50&fm=jpg",
//           srcSet:
//             "//images.ctfassets.net/qyzml03adckj/3LPBETg8iQQBIouzz8LKkp/1984946dd3803afa73003210bf23a622/avacado.jpg?w=69&h=46&fl=progressive&q=50&fm=jpg 69w,\n//images.ctfassets.net/qyzml03adckj/3LPBETg8iQQBIouzz8LKkp/1984946dd3803afa73003210bf23a622/avacado.jpg?w=138&h=92&fl=progressive&q=50&fm=jpg 138w,\n//images.ctfassets.net/qyzml03adckj/3LPBETg8iQQBIouzz8LKkp/1984946dd3803afa73003210bf23a622/avacado.jpg?w=275&h=183&fl=progressive&q=50&fm=jpg 275w",
//           sizes: "(min-width: 275px) 275px, 100vw",
//         },
//       },
//     },
//   },
// };
