import * as R from "ramda";

import { shape, string } from "prop-types";

export default R.compose(
  R.applySpec({
    title: R.prop("title"),
    slug: R.prop("slug"),
    color: R.compose(
      R.unless(
        R.isNil,
        R.applySpec({
          title: R.prop("title"),
          colorValue: R.prop("colorValue"),
        }),
      ),
      R.prop("color"),
    ),
  }),
  R.defaultTo({}),
);

export const tagFields = {
  title: string.isRequired,
  slug: string.isRequired,
  color: shape({ title: string.isRequired, colorValue: string.isRequired })
    .isRequired,
};
