import {} from "prop-types";
import "./timeline-page.scss";

import * as R from "ramda";

import { graphql, useStaticQuery } from "gatsby";
import { useEffect, useState } from "react";

import ContainerBleed from "../Container/ContainerBleed.jsx";
import OutlineTextPromo from "../OutlineTextPromo/OutlineTextPromo.jsx";
import Page from "../Page/Page.jsx";
import Promo2Up from "../Promo2Up/Promo2Up.jsx";
import SignUp from "../SignUp/SignUp.jsx";
import Timeline from "../Timeline/Timeline.jsx";
import TimelinePromo from "../TimelinePromo/TimelinePromo.jsx";
import extractOutlineTextPromo from "../../common/extractOutlineTextPromo.js";
import extractPromo2Up from "../../common/extractPromo2Up.js";
import extractTimeline from "../../common/extractTimeline.jsx";
import extractTimelinePromo from "../../common/extractTimelinePromo.js";

const propTypes = {};

const TimelinePage = (props) => {
  const contentfulData = useStaticQuery(query);

  const [hasMounted, setHasMounted] = useState(false);

  const data = R.compose(
    R.evolve({
      timeline: extractTimeline,
      timelinePromo: R.unless(R.isNil, extractTimelinePromo),
      timelineOutlineTextPromo: R.unless(R.isNil, extractOutlineTextPromo),
      timelinePromo2Up: R.unless(R.isNil, extractPromo2Up),
    }),
    R.defaultTo({}),
    R.prop("contentfulTimelinePage"),
  )(contentfulData);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const {
    timelinePromo,
    timeline,
    timelineOutlineTextPromo,
    timelinePromo2Up,
  } = data;

  return (
    <Page theme="dark" pageTitle="Timeline" className="timeline-page">
      {hasMounted && (
        <>
          {timelinePromo && <TimelinePromo hideCTA {...timelinePromo} />}
          {timeline && <Timeline {...timeline} />}
        </>
      )}
      <ContainerBleed className="timeline-page__gradient">
        {timelineOutlineTextPromo && (
          <OutlineTextPromo {...timelineOutlineTextPromo} />
        )}
        {timelinePromo2Up && <Promo2Up {...timelinePromo2Up} />}
        <SignUp
          title="Join Our Movement"
          subtitle="Sign up for our emails and learn how to get involved in Vision for Justice and help transform our criminal-legal system."
        />
      </ContainerBleed>
    </Page>
  );
};

TimelinePage.propTypes = propTypes;

export default TimelinePage;

const query = graphql`
  {
    contentfulTimelinePage(id: { eq: "73de8f53-c07d-57ee-b674-fa3fbe73f1bd" }) {
      id
      timelinePromo {
        ...TimelinePromo
      }
      timeline {
        ...Timeline
      }
      timelineOutlineTextPromo {
        ...OutlineTextPromo
      }
      timelinePromo2Up {
        promo2UpTitle
        promo2UpSubtitle {
          raw
        }
        promo1 {
          ...ImageBlock
        }
        promo2 {
          ...ImageBlock
        }
      }
    }
  }
`;
