import "./media-block.scss";

import { node, number } from "prop-types";
import { useRef, useState } from "react";

import cx from "classnames";
import useScrollama from "../../common/useScrollama.js";

const propTypes = {
  offset: number,
  children: node.isRequired,
};

const MediaBlock = (props) => {
  const { children, className, offset } = props;

  const [shouldAnimate, setShouldAnimate] = useState(false);

  const mediaRef = useRef();

  useScrollama(mediaRef, {
    offset: offset ? offset : 0.8,
    // debug: true,
    onStepEnter: (response) => setShouldAnimate(true),
    once: true,
  });

  return (
    <div
      ref={mediaRef}
      className={cx("media-block", className, {
        "media-block--enter-animated": shouldAnimate,
      })}
    >
      {children}
    </div>
  );
};

MediaBlock.propTypes = propTypes;
export default MediaBlock;
