import * as R from "ramda";

import Container100 from "../components/Container/Container100.jsx";
import MediaBlock from "../components/MediaBlock/MediaBlock.jsx";
import MovementMoment from "../components/MovementMoment/MovementMoment.jsx";
import Quote from "../components/Quote/Quote.jsx";
import TimelineSlide from "../components/TimelineSlide/TimelineSlide.jsx";
import extractImage from "./extractImage.jsx";
import extractImageBlock from "./extractImageBlock.jsx";
import extractInfoBlock from "./extractInfoBlock.js";
import extractQuote from "./extractQuote.jsx";

const extractTimelineSlide = R.compose(
  R.applySpec({
    text: R.prop("timelineSlideText"),
    year: R.prop("timelineSlideYear"),
  }),
  R.evolve({
    timelinSlideText: R.identity,
    timelinSlideYear: R.identity,
  })
);

const extractSlides = R.compose(
  R.cond([
    [
      R.propEq("__typename", "ContentfulInfoBlock"),
      R.compose(
        (data) => (
          <div className="timeline__center">
            <MovementMoment {...data} />
          </div>
        ),
        R.applySpec({
          boxTitle: R.pathOr("Movement Moment", ["sectionTitle"]),
          headline: R.prop("title"),
          description: R.prop("text"),
          image: R.prop("image"),
        }),
        extractInfoBlock
      ),
    ],
    [
      R.propEq("__typename", "ContentfulQuote"),
      R.compose(
        (data) => (
          <div className="timeline__center">
            <Container100>
              <Quote {...data} />
            </Container100>
          </div>
        ),
        extractQuote
      ),
    ],
    [
      R.propEq("__typename", "ContentfulTimelineSlide"),
      R.compose(
        (data) => (
          <div className="timeline__center">
            <TimelineSlide {...data} />
          </div>
        ),
        extractTimelineSlide
      ),
    ],
    [
      R.propEq("__typename", "ContentfulImageBlock"),
      R.compose(
        (image) => (
          <div className="timeline__center">
            <MediaBlock children={image} />
          </div>
        ),
        R.prop("image"),
        extractImageBlock
      ),
    ],
  ])
);

const extractSections = R.map(
  R.evolve({
    title: R.identity,
    years: R.identity,
    headline: R.identity,
    subtitle: R.identity,
    slides: R.map(extractSlides),
    backgroundImage: extractImage,
  })
);

export default R.compose(
  R.applySpec({
    sections: R.compose(extractSections, R.prop("timelineSections")),
  }),
  R.defaultTo({})
);
