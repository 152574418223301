import "./timeline-promo.scss";

import { arrayOf, bool, node, object } from "prop-types";
import { useEffect, useRef, useState } from "react";

import ContentfulLink from "../ContentfulLink/ContentfulLink";
import TimelinePromoImage from "./TimelinePromoImage.jsx";
import cx from "classnames";
import { gsap } from "gsap";
import { scrollToElement } from "../../common/scrollHelpers.js";
import useAOS from "../../common/useAOS.js";
import useScrollama from "../../common/useScrollama";

const propTypes = {
  headline: node,
  description: node,
  images: arrayOf(node),
  ctaLink: object,
  backgroundImage: node,
  hideCTA: bool,
};

const TimelinePromo = (props) => {
  const { headline, description, images, backgroundImage, ctaLink, hideCTA } =
    props;

  useAOS();

  const imageRefs = useRef([]);

  // Initialize image refs
  useEffect(() => {
    imageRefs.current = imageRefs.current.slice(0, images.length);
  }, [images]);

  const scrollToTimeline = () => {
    if (typeof document === "undefined") return;

    const timelineEl = document.querySelector(".js-timeline");

    if (timelineEl) {
      scrollToElement(timelineEl);
    }
  };

  // Initial animation to scale up and fade in the items
  useEffect(() => {
    gsap
      .timeline()
      .set(imageRefs.current, { scale: 0.7, opacity: 0 }, 0)
      .to(
        imageRefs.current,
        {
          duration: 2,
          ease: "Expo.easeOut",
          scale: 1,
          stagger: { amount: 0.6, grid: "auto", from: "center" },
        },
        0,
      )
      .to(
        imageRefs.current,
        {
          duration: 3,
          ease: "Power1.easeOut",
          opacity: 0.9,
          stagger: { amount: 0.6, grid: "auto", from: "center" },
        },
        0,
      );
  }, [imageRefs]);

  const buttonRef = useRef();
  const [shouldAnimate, setShouldAnimate] = useState(false);

  /* Scrollama Effects */
  useScrollama(buttonRef, {
    offset: 0.8,
    // debug: true,
    onStepEnter: (response) => {
      setShouldAnimate(true);
      setTimeout(() => setShouldAnimate(false), 800);
    },
  });

  return (
    <div
      className={cx("timeline-promo container container--bleed", {
        "container--has-background": backgroundImage,
      })}
    >
      {backgroundImage && (
        <div className="timeline-promo__background-image">
          {backgroundImage}
        </div>
      )}
      <div className="timeline-promo__assets">
        {images.map((image, idx) => (
          <TimelinePromoImage
            ref={(e) => (imageRefs.current[idx] = e)}
            key={idx}
            index={idx}
            image={image}
          />
        ))}
      </div>
      <div className="timeline-promo__content-wrapper container container--100">
        {headline && (
          <div
            data-aos="fade-down"
            data-aos-offset="0"
            className="timeline-promo__headline"
          >
            {headline}
          </div>
        )}
        {description && (
          <div
            data-aos="fade-down"
            data-aos-offset="0"
            className="timeline-promo__description"
          >
            {description}
          </div>
        )}
        {hideCTA && (
          <button
            ref={buttonRef}
            className={cx("timeline-promo__scroll", {
              "timeline-promo__scroll--animated": shouldAnimate,
            })}
            type="button"
            onClick={scrollToTimeline}
          >
            <ScrollIcon />
          </button>
        )}
        {ctaLink && !hideCTA && (
          <ContentfulLink
            className="timeline-promo__cta btn btn--big"
            {...ctaLink}
          />
        )}
      </div>
    </div>
  );
};

const ScrollIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
      <defs />
      <defs>
        <filter id="a" colorInterpolationFilters="auto">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        filter="url(#a)"
        transform="translate(-684 -985)"
      >
        <g fill="#FFF" fillRule="nonzero">
          <path d="M720 985c19.85 0 36 16.149 36 36s-16.15 36-36 36-36-16.149-36-36 16.15-36 36-36zm0 69.204c18.309 0 33.204-14.895 33.204-33.204S738.309 987.796 720 987.796s-33.204 14.895-33.204 33.204 14.896 33.204 33.204 33.204z" />
          <path d="M719.526 1008.068c.773 0 1.398.626 1.398 1.398v20.038a1.398 1.398 0 11-2.796 0v-20.038c0-.772.625-1.398 1.398-1.398z" />
          <path d="M728.587 1021.296a1.398 1.398 0 01.884 2.482l-9.063 7.393a1.397 1.397 0 01-1.738.024l-8.563-6.615a1.397 1.397 0 111.709-2.213l7.685 5.936 8.202-6.69c.262-.213.574-.317.884-.317z" />
        </g>
      </g>
    </svg>
  );
};

TimelinePromo.propTypes = propTypes;

export default TimelinePromo;
